import { Dispatch, SetStateAction } from 'react';
import { Flavour } from '../types/utils';
import { UserDataIndex } from '../../vorotypes/types/user';
import api from '../services/api';
import db from '../services/db';
import theme from '../theme';
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { Template } from '../../vorotypes/types/formTemplate';
import { Participant } from '../../vorotypes/types/participant';
import { uploadPresignedFile } from '../services/files';
import voroQueryClient from '../services/voroQueryClient';
import { Photo } from '../types/form';
import { S3FileWrapper } from '../../vorotypes/types/utils';
import { AddFormAnswerPostData } from '../../vorotypes/types/routes.app.requests';
import { CoworkerListRes } from '../../vorotypes/types/routes.app.responses';

export function getFlavour(): Flavour {
  const hostname = window.location.hostname;
  const domains = hostname.split('.');
  if (!!domains && domains?.length >= 2) {
    const index = domains.indexOf('brf');
    if (index >= 0 && index < domains.length - 1) return 'brf';
  }
  return null;
}

/**
 * Fetches the data that must be filled to register a user
 * @param mode defines which selection will be returned:
 *    - 'only-required' to fetch only the data *required* for register
 *    - 'all' to fetch all data that can be collected during register
 * @param setFn setState function of the state that will store the userDataIndex
 * @author Douglas Flores
 */
export async function fetchUserDataIndex(
  mode: 'only-required' | 'all',
  setFn: Dispatch<SetStateAction<UserDataIndex | undefined>>
) {
  try {
    const apires = await api.get<UserDataIndex>('/app/userDataIndex/update', {
      params: { mode }
    });
    setFn(apires.data);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Gets the date of when the last update verification was done
 * @returns a formated string date of when the app last verified if there was a pending update or null if it was not able to fetch the date
 */
export async function getDateOfLastUpdateVerification() {
  try {
    const dbres = await db
      .table('misc')
      .where('key')
      .equals('dateOfLastUpdateVerification')
      .toArray();

    if (dbres.length < 1) throw new Error('Could not find verification date');
    if (typeof dbres[0].value !== 'string') throw new Error('Invalid date');

    const formatedDate = dayjs(dbres[0].value).format('DD/MM/YYYY - HH:mm:ss');
    return formatedDate;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Sets the app's theme color
 * @param color a valid hexadecimal color
 * @author Douglas Flores
 */
export function setAppThemeColor(color?: string) {
  const metaTag = document.getElementById('theme-color');
  if (color && metaTag) metaTag.setAttribute('content', color);
}

/**
 * Resets the app's theme color to the default background color
 * @author Douglas Flores
 */
export function resetAppThemeColor() {
  const metaTag = document.getElementById('theme-color');
  if (metaTag)
    metaTag.setAttribute('content', theme.palette.background.default);
}

/**
 * Extracts most data from an axios error object excluding the function signatures
 * This is useful to cache or POST the error as a JSON.
 * @param error an axios error
 * @returns an axios error without function signatures
 * @author Douglas Flores
 */
export function getCacheableAxiosError(error: AxiosError) {
  return {
    response: {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      config: {
        baseURL: error.response?.config?.baseURL,
        headers: error.response?.config.headers,
        maxBodyLength: error.response?.config.maxBodyLength,
        maxContentLength: error.response?.config.maxContentLength,
        method: error.response?.config.method,
        timeout: error.response?.config.timeout
      }
    },
    message: error.message
  };
}

/**
 * Gets a list of all participants of a formAnswer
 * @param template the template from a formTemplate
 * @param answer the answer to each question in the template
 * @returns a list of participants from the target answer
 */
export async function getParticipantsFromAnswer(
  template: Template,
  answer: { [key: string]: any }
): Promise<Array<Participant> | undefined> {
  try {
    const queryData = await voroQueryClient.getQueryData<CoworkerListRes>([
      'coworkers'
    ]);
    const coworkers = queryData?.coworkers;

    const all_fields = template.steps.flatMap(step => step.fields);
    const user_fields = all_fields.filter(
      field =>
        ['User', 'Users'].includes(field?.type ?? '') ||
        field.field_id === 'performers'
    );
    const users: Array<Participant | undefined> = user_fields.flatMap(field => {
      const field_answer = answer[field.field_id];
      if (Array.isArray(field_answer)) {
        return field_answer.map(id => {
          const coworker = coworkers?.find(c => c.id === id);
          return {
            id_user: id,
            name: coworker?.name ?? null,
            job: field.properties?.job
          };
        });
      } else if (typeof field_answer === 'number') {
        const coworker = coworkers?.find(c => c.id === field_answer);
        return [
          {
            id_user: field_answer,
            name: coworker?.name ?? null,
            job: field.properties?.job
          }
        ];
      } else return undefined;
    });
    return (users?.filter(u => !!u) as Participant[]) ?? []; // Just adding typecast because typescript is not smart enough to filter the undefined out
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

/**
 * Determines if a user certification is expired, about to expire or ok
 * @param validThrough certification's expiration date - ISODate
 * @param warningPeriodInDays threshold in days to consider the certification is about to expire
 * @returns a status of a user certification regarding its validity
 * @author Douglas Flores
 */
export function getCertificationStatus(
  validThrough: string,
  warningPeriodInDays: number
): 'expired' | 'warning' | 'ok' {
  const todayDate = new Date().toISOString();
  const warningThresholdDate = new Date();
  warningThresholdDate.setDate(
    warningThresholdDate.getDate() + (warningPeriodInDays ?? 0)
  );
  const warningThresholdDateString = warningThresholdDate.toISOString();
  const status =
    validThrough < todayDate
      ? 'expired'
      : validThrough <= warningThresholdDateString
      ? 'warning'
      : 'ok';
  return status;
}

/**
 * Gets the first name of a full name
 * @param name a full name
 * @returns the first name
 * @author Douglas Flores
 */
export function getFirstName(name?: string) {
  if (typeof name === 'string') {
    const names = name.split(' ');
    return names[0];
  } else {
    return null;
  }
}

/**
 * Gets the value of an object's attribute
 * @param path a string array representing a path to an object's attribute
 * @param obj any object
 * @returns obj's path value
 * @author Douglas Flores
 */
export function getValueFromPathArray(pathArray: string[], obj: any): any {
  if (pathArray.length > 1) {
    const key = pathArray[0];
    return getValueFromPathArray(pathArray.slice(1), obj[key]);
  } else if (pathArray.length === 1) return obj[pathArray[0]];
  else if (pathArray.length === 0) return obj;
  else return undefined;
}

/**
 * Uploads all photo objects of an object that weren't uploaded yet and
 * updates the object to include bucketData instead of base64
 * @param object a object with photos
 * @param directory the directory to upload the photos to
 * @returns the object with bucketData for photos instead of base64
 * @author Douglas Flores
 */
export async function uploadPhotosFromObject(
  object: { [key: string]: any },
  directory: string
) {
  try {
    let obj = { ...object };
    let unuploadedCount = 0;
    let uploadCount = 0;

    // Creating a recursive function to loop through all the answer
    async function loopThrough(looper: any) {
      if (Array.isArray(looper)) {
        // Recursively exploring all array's items
        for (let i = 0; i < looper?.length; i++) {
          const element = looper[i];
          looper[i] = await loopThrough(element);
        }
        // Return the possibly modified array
        return [...looper];
      } else if (!!looper && typeof looper === 'object') {
        let keys = Object.keys(looper);
        // Recursively exploring all object's keys
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (['base64', 'base64Img'].includes(key)) {
            unuploadedCount++;
            /* Uploading images through presigned URLs */
            const { apires, awsres, bucketData } = await uploadPresignedFile(
              directory,
              looper as Photo
            );
            /* Checking for errors in presigned posts */
            if (!apires || typeof apires?.data === 'string') break;
            /* if successfully uploaded */
            if (awsres?.status === 200) {
              const { base64, base64Img, ...rest } = looper;
              looper = { ...rest, bucketData } as S3FileWrapper;
              console.log(bucketData);
              uploadCount++;
            }
            // Ending the recursion in this branch
            break;
          } else if (typeof looper[key] === 'object') {
            // Another dive
            looper = {
              ...looper,
              [key]: await loopThrough(looper[key])
            };
          }
        }
        // Returning the possibly modified object
        return looper;
      }
      return looper;
    }

    // Starting the recursion
    obj = await loopThrough(obj);

    return {
      object: obj,
      unuploadedCount,
      uploadCount
    };
  } catch (error) {
    console.error(error);
    return {
      object,
      uploadCount: 0,
      unuploadedCount: Infinity
    };
  }
}

/**
 * Uploads all photos from AddFormAnswerPostData and returns
 * the object updated with the corresponding bucketDatas
 * @param postData the data sent to POST a formAnswer
 * @param id_company the company´s id
 * @returns the postData object with all photos uploaded (bucketData)
 * @author Douglas Flores
 */
export async function uploadPhotosBeforePost(
  postData: AddFormAnswerPostData,
  id_company: number
) {
  const responseFA = { ...postData };
  /** Defining formanswer's directory */
  const uploadDirectory = `company_${id_company}/${
    responseFA.ulid ?? 'unidentified'
  }`;
  const photosDirectory = `${uploadDirectory}/photos`;
  /* Uploading nonExecAnswer´s Photos */
  if (!!responseFA?.nonExecAnswer) {
    const nonExecPhotosDirectory = `${uploadDirectory}/nonExecution/photos`;
    const { object: nonExecWithPhotos } = await uploadPhotosFromObject(
      responseFA.nonExecAnswer,
      nonExecPhotosDirectory
    );
    responseFA.nonExecAnswer = nonExecWithPhotos;
  }
  /* Uploading Answer´s Item´s Photos */
  const { object: answerWithPhotos } = await uploadPhotosFromObject(
    responseFA.answer.items,
    photosDirectory
  );
  responseFA.answer.items = answerWithPhotos;
  /* Uploading Answer´s Signature´s Photos */
  if (responseFA?.answer?.signatures) {
    const signaturesDirectory = `${uploadDirectory}/signatures`;
    const { object: uploadedSignatures } = await uploadPhotosFromObject(
      { signatures: responseFA.answer.signatures },
      signaturesDirectory
    );
    responseFA.answer.signatures = uploadedSignatures.signatures;
  }
  /* Uploading Authentication´s Photos */
  if (responseFA?.answer?.authentication) {
    const authenticationDirectory = `${uploadDirectory}/authentication`;
    const { object: uploadedAuth } = await uploadPhotosFromObject(
      responseFA.answer.authentication,
      authenticationDirectory
    );
    responseFA.answer.authentication = uploadedAuth;
  }

  return responseFA;
}
