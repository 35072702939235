import jwtDecode from 'jwt-decode';
import FormData from 'form-data';
import api from './api';
import Cookies from 'js-cookie';
import axios from 'axios';

// TODO: add offline capabilities
export default async function logAppEvent(text: string) {
  try {
    const data = {
      email: Cookies.get('email'),
      errMsg: text
    };
    // Read more about env MODES in https://vitejs.dev/guide/env-and-mode.html#node-env-and-modes
    if (import.meta.env.PROD) {
      api.post('/app/logAppEvent', data);
    }
    return true;
  } catch (err) {
    console.log(`[ERR @ chatOps]  ${err}`);
    return false;
  }
}

export async function logDiscord(message: string, attachment?: FormData) {
  const token = Cookies.get('token');
  try {
    if (!token) return false;
    if (!attachment) {
      const decodedToken: any = jwtDecode(token);
      const body = {
        content: `[LOG] usuário ${
          decodedToken?.email ?? ''
        } (C${decodedToken?.id_company}-U${decodedToken?.id_user}) | ${new Date().toISOString()} (UTC) - ${
          window?.location?.hostname ?? ''
        }: ${message}`
      };
      await axios.post(
        `https://discord.com/api/webhooks/1169012486255693904/s7R5fPa_6DUn2C0dOYfzGEoNoK8RPG2-Y6MKE1ntbXgnVjwtGF5lPcSdZGLg5-EO6FJY`,
        body
      );
      return true;
    } else {
      // Textual message
      const stringContent = JSON.stringify({ content: message });
      attachment.append('payload_json', stringContent);
      // Posting to discord
      await axios.post(
        `https://discord.com/api/webhooks/1169012486255693904/s7R5fPa_6DUn2C0dOYfzGEoNoK8RPG2-Y6MKE1ntbXgnVjwtGF5lPcSdZGLg5-EO6FJY`,
        attachment
      );
      return true;
    }
  } catch (err) {
    console.error(err);
  }
}
