import { makeStyles } from '@mui/styles';

const useFieldBinaryStyles = makeStyles(theme => ({
  selectedOption: {
    border: '1px solid',
    borderColor: 'transparent',
    padding: '4px 16px'
  },
  notSelectedOption: {
    padding: '4px 16px'
  }
}));

export default useFieldBinaryStyles;
