import {
  FormSignatureDB,
  FormSignaturePayload,
  Signature
} from '../types/formSignature';

/**
 * Converts a signature into a FormSignaturePayload
 * @param signature a signature from the front-end
 * @param ulid_formAnswer the target formAnswer's ulid
 * @returns a payload to add a signature into the formSignature table
 * @author Douglas Flores
 */
export function signatureToFormSignaturePayload(
  signature: Signature,
  ulid_formAnswer: string
): FormSignaturePayload {
  return {
    id_user: signature.id,
    ulid_formAnswer,
    signedDatetime: signature.date,
    personalId: signature?.personalId ?? null,
    photo: signature?.photo ?? null,
    signatureImage: signature?.signatureImage ?? null
  };
}

/**
 * Converts a FormSignatureDB into a Signature
 * @param formSignature a formSignature from the DB
 * @returns a front-end signature object
 * @author Douglas Flores
 */
export function formSignatureToSignature(
  formSignature: FormSignatureDB
): Signature {
  return {
    id: formSignature.id_user,
    date: formSignature.signedDatetime,
    personalId: formSignature?.personalId ?? undefined,
    photo: formSignature?.photo ?? undefined,
    signatureImage: formSignature?.signatureImage ?? undefined
  };
}
