import { useState } from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');
import api from '../services/api';
import { TermsAndConditionsElement } from '../types/utils';
import { FormAudit } from '../../vorotypes/types/formAudit';
import { FormInspection } from '../../vorotypes/types/formInspection';
import { certificationsQueryFn, defaultQueryFn } from '../utils/queries';
import { isAuthenticated } from '../services/auth';
import { useVoroQuery } from './voroQuery';
import {
  Certification,
  UserCertification
} from '../../vorotypes/types/certification';
import { UserFrontData } from '../../vorotypes/types/user';
import {
  UseQueryResult,
  UseQueryResultState,
  VoroQueryOptions
} from '../types/voroquery';
import voroQueryClient from '../services/voroQueryClient';
import { BusinessUnit } from '../../vorotypes/../vorotypes/types/businessUnit';
import {
  CompanyNameRes,
  CompanyServicesRes,
  CoworkerListRes,
  DocumentsHistoryRes,
  PublicAnswerRes,
  VoroRoleRes
} from '../../vorotypes/types/routes.app.responses';
import { FormTemplate } from '../../vorotypes/types/formTemplate';
import axios from 'axios';
import {
  AppLocalFormAnswer,
  FormAnswerAndTemplate
} from '../../vorotypes/types/formAnswer';
import { FormAnswerExemplary } from '../../vorotypes/types/formAnswerExemplary';
import { DqsTopic, DqsTopicExtended } from '../../vorotypes/types/dqsTopic';
import { VoroRoleDisplay } from '../../vorotypes/types/authorization';

import { FormSignatureDB } from '../../vorotypes/types/formSignature';
import db from '../services/db';
import { formSignatureToSignature } from '../../vorotypes/compatibility/signature.compat';

type VoroQueryHookParams<T> = Omit<VoroQueryOptions<T>, 'queryKey' | 'queryFn'>;

/**
 * A hook to fetch a list of performers
 * @returns an useQuery hook to fetch a list of performers
 */
export const useBusinessUnits = (
  params?: VoroQueryHookParams<BusinessUnit[]>
) => {
  return useVoroQuery({
    queryKey: ['businessUnits'],
    queryFn: () => defaultQueryFn<BusinessUnit[]>('/app/businessUnits'),
    ...params
  });
};

/**
 * A hook to fetch certifications
 * @returns an useVoroQuery hook to fetch certifications
 */
export const useCertifications = (
  params?: VoroQueryHookParams<Certification[]>
) => {
  return useVoroQuery({
    queryKey: ['certifications'],
    queryFn: certificationsQueryFn,
    ...params
  });
};

/**
 * A hook to fetch the company name
 * @returns an useVoroQuery hook to fetch company name
 */
export const useCompanyName = (
  params?: VoroQueryHookParams<CompanyNameRes>
) => {
  return useVoroQuery({
    queryKey: ['companyName'],
    queryFn: () => defaultQueryFn<CompanyNameRes>('/app/companyName'),
    ...params
  });
};

/**
 * A hook to fetch company services
 * @description is not used yet
 * @returns an useVoroQuery hook to fetch company services
 */
export const useCompanyServices = () => {
  return useVoroQuery({
    queryKey: ['companyServices'],
    queryFn: () => defaultQueryFn<CompanyServicesRes>('/app/companyServices')
  });
};

/**
 * A hook to fetch a list of DQS topics
 * @returns an useQuery hook to fetch a list of DQS topics
 */
export const useDqsTopics = (
  params?: VoroQueryHookParams<DqsTopicExtended[]>
) => {
  return useVoroQuery({
    queryKey: ['dqsTopics'],
    queryFn: async () => {
      const token = Cookies.get('token');
      if (!token) throw Error('No token');
      const apires = await api.get<DqsTopic[]>('/app/dqsTopics');
      const response: DqsTopicExtended[] = apires.data.map(dqsTopic => {
        const auxDate = new Date();
        auxDate.setMonth(dqsTopic.referenceMonth);
        auxDate.setFullYear(dqsTopic.referenceYear);
        const auxDateStr = dayjs(auxDate)
          .locale('pt-br')
          .format('MMMM/YYYY')
          .toLowerCase();
        const referenceMonthYear =
          auxDateStr.charAt(0).toUpperCase() + auxDateStr.slice(1);
        return { ...dqsTopic, referenceMonthYear };
      });
      return response;
    },
    ...params
  });
};

/**
 * A hook to fetch when the email was verified
 * @returns an useVoroQuery hook to fetch company services
 */
export const useEmailVerifiedAt = () => {
  return useVoroQuery({
    queryKey: ['emailVerifiedAt'],
    queryFn: () => defaultQueryFn('/user/emailVerifiedAt')
  });
};

/**
 * A hook to fetch cached form-answers
 * @param onSuccess callback to be executed on success
 * @param onError callback to be executed on error
 * @returns an useQuery hook to fetch form-answers
 */
export const useFetchCachedAnswers = (
  params?: VoroQueryHookParams<AppLocalFormAnswer[]>
) => {
  return useVoroQuery({
    queryKey: ['cachedFormAnswers'],
    queryFn: async () => {
      /* Getting local form-answers */
      const localFAs = await voroQueryClient.getQueryData<AppLocalFormAnswer[]>(
        ['cachedFormAnswers']
      );
      const faCmpData =
        localFAs?.map(fa =>
          Object({
            status: fa?.answerStatus?.status,
            date: fa?.filledDate,
            ulid: fa?.ulid ?? fa?.answer?.metadata.ulid
          })
        ) ?? [];
      /* Getting form-answers from api */
      const apires = await api.get<FormAnswerAndTemplate[]>(
        '/app/cachedFormAnswers/v2',
        {
          params: {
            cached: faCmpData,
            limit: 10
          }
        }
      );
      if (apires.status === 204) {
        console.log('[LOG] Cached form answers up to date!');
        return localFAs ?? [];
      }
      const fetchedAnswers = apires.data;
      const onlyLocallyAvailable =
        localFAs?.filter(
          fa =>
            fa.onlyLocallyAvailable &&
            !fetchedAnswers.find(
              ffa => fa.answer.metadata.ulid === ffa?.answer?.metadata?.ulid
            )
        ) ?? [];

      const newCachedAnswers = [...fetchedAnswers, ...onlyLocallyAvailable];

      return newCachedAnswers;
    },
    staleTime: 5 * 60 * 1000, // 5 min
    ...params
  });
};

export const useFetchDocumentsHistory = (
  onSuccess?: (data?: any) => void,
  onError?: (err: unknown) => void
) => {
  return useVoroQuery({
    queryKey: ['documentsHistory'],
    queryFn: async () => {
      /* Getting form-answers from api */
      const apires = await api.get<DocumentsHistoryRes>(
        '/app/documentsHistory'
      );
      let fetchedAnswers = apires.data;
      return fetchedAnswers;
    },
    onSuccess: onSuccess,
    onError: onError
  });
};

/**
 * A hook to fetch a form-answer
 * @deprecated try using useFetchCachedAnswers instead
 * @param ulid the ulid of the form-answer to be fetched
 * @returns an useQuery hook to fetch a form-answer
 * TODO: Use a VoroQuery hook instead of doing the hook manually here
 */
export function useFormAnswer<TData>(
  ulid: string,
  config?: {
    disableAlerts?: boolean;
  }
): Omit<UseQueryResult<TData>, 'refetch'> & { refetch: () => void } {
  const [result, setResult] = useState<UseQueryResultState<TData>>({
    data: undefined,
    status: 'idle'
  });
  const idle = () => setResult({ ...result, status: 'idle' });

  if (result.status === 'idle' && !!ulid) {
    setResult({ ...result, status: 'loading' });
    voroQueryClient
      .getQueryData<TData[]>(['cachedFormAnswers'])
      .then(queryData => {
        if (!queryData) {
          setResult({ ...result, status: 'success' });
        } else {
          const selectedAnswer = queryData?.find(
            (answer: any) =>
              answer?.answer?.metadata?.ulid === ulid ||
              answer?.id === Number.parseInt(ulid)
          );
          if (!selectedAnswer && navigator.onLine) {
            api
              .get(`/app/formAnswer/get/${ulid}`)
              .then(res => setResult({ data: res.data, status: 'success' }))
              .catch(err => {
                console.error(err);
                if (axios.isAxiosError(err) && err.response?.status === 403) {
                  const msg =
                    err?.response?.data ?? 'Erro 403: usuário não autorizado';
                  if (!config?.disableAlerts) alert(msg);
                  setResult({ ...result, status: 'forbidden' });
                } else setResult({ ...result, status: 'error' });
              });
          } else {
            setResult({ data: selectedAnswer, status: 'success' });
          }
        }
      })
      .catch(err => {
        console.error(err);
        setResult({ ...result, status: 'error' });
      });
  }
  return {
    ...result,
    isReady: result.status === 'success',
    isQuerying: ['loading', 'fetching'].includes(result.status),
    isLoading: result.status === 'loading',
    isFetching: result.status === 'fetching',
    isError: result.status === 'error',
    isEmpty: result.status === 'empty',
    isIdle: result.status === 'idle',
    isStale: result.status === 'stale',
    refetch: idle
  };
}

/**
 * A hook to fetch a form-answer
 * @param id the id of the form-answer to be fetched
 * @returns an useQuery hook to fetch a form-answer
 */
export function useOnlineFormAnswer<TData>(
  id: number
): Omit<UseQueryResult<TData>, 'refetch'> & { refetch: () => void } {
  const [result, setResult] = useState<UseQueryResultState<TData>>({
    data: undefined,
    status: 'idle'
  });
  const idle = () => setResult({ ...result, status: 'idle' });

  if (result.status === 'idle' && !!id) {
    setResult({ ...result, status: 'loading' });
    api
      .get<TData>(`/app/formAnswer/get/${id}`)
      .then(res => {
        const queryData = res?.data;
        if (!queryData) setResult({ ...result, status: 'error' });
        else {
          setResult({ data: queryData, status: 'success' });
        }
      })
      .catch(err => {
        console.error(err);
        setResult({ ...result, status: 'error' });
      });
  }
  return {
    ...result,
    isReady: result.status === 'success',
    isQuerying: ['loading', 'fetching'].includes(result.status),
    isLoading: result.status === 'loading',
    isFetching: result.status === 'fetching',
    isError: result.status === 'error',
    isEmpty: result.status === 'empty',
    isIdle: result.status === 'idle',
    isStale: result.status === 'stale',
    refetch: idle
  };
}

/**
 * A hook to fetch form-templates
 * @param onSuccess callback to run on successful fetch
 * @param onError callback to run on error
 * @returns a VoroQuery hook to fetch form-templates
 */
export const useFormTemplates = (
  params?: VoroQueryHookParams<FormTemplate[]>
) => {
  return useVoroQuery({
    queryKey: ['formTemplates'],
    disabled: !isAuthenticated(),
    queryFn: async () => {
      const apires = await api.get<FormTemplate[]>('/app/formTemplatesV2');
      return apires.data;
    },
    ...params
  });
};

/**
 * A hook to fetch a publicFormAnswer
 * @param ulid the ulid of the form-answer to be fetched
 * @param id_company the form-answer's company
 * @param onSuccess callback fired on success
 * @param onError callback fired on error
 * @returns a VoroQuery hook to fetch a public form answer
 */
export const useFetchPublicAnswer = (
  ulid: string,
  id_company: string,
  onSuccess?: (data?: any) => void,
  onError?: (err: unknown) => void
) => {
  return useVoroQuery({
    queryKey: ['publicAnswer', ulid, id_company],
    queryFn: async () => {
      try {
        const headers = { 'vorotech-required-version': '^1.1.0' };
        const apires = await api.get<PublicAnswerRes>(
          `/app/publicAnswer/${id_company}/${ulid}`,
          { headers }
        );
        return apires.data;
      } catch (err) {
        console.error(err);
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            return { notFound: true };
          } else if (err.response?.status === 410) {
            return { expired: true };
          }
        }
      }
    },
    onSuccess: onSuccess,
    onError: onError
  });
};

/**
 * A hook to fetch a list of coworkers
 * @returns an useQuery hook to fetch a list of coworkers
 */
type CoworkersParams = {
  disabled?: boolean;
  fetchOnlyOnEmptyCache?: boolean;
};
export const useCoworkers = (params?: CoworkersParams) => {
  return useVoroQuery({
    queryKey: ['coworkers'],
    queryFn: () => defaultQueryFn<CoworkerListRes>('/app/coworkersList'),
    disabled: params?.disabled,
    fetchOnlyOnEmptyCache: params?.fetchOnlyOnEmptyCache
  });
};

/**
 * A hook to fetch the termsAndConditions
 * @returns an useQuery hook to fetch the termsAndConditions
 */
export const useTermsAndConditions = () => {
  return useVoroQuery({
    queryKey: ['termsAndConditions'],
    queryFn: async () => {
      const apires = await api.get<{ elements: TermsAndConditionsElement[] }>(
        '/termsAndConditions'
      );
      return apires.data.elements;
    }
  });
};

/**
 * A hook to fetch the certifications of the logged user
 * @returns an useQuery hook to fetch the user's certifications
 */
export const useUserCertifications = () => {
  return useVoroQuery({
    queryKey: ['userCertifications'],
    queryFn: () =>
      defaultQueryFn<UserCertification[]>('/app/activeUserCertifications')
  });
};

/**
 * A hook to fetch userData
 * @returns an useQuery hook to fetch userData
 */
export const useUserData = (params?: {
  disabled?: boolean;
  fetchOnlyOnEmptyCache?: boolean;
}) => {
  return useVoroQuery({
    queryKey: ['userData'],
    queryFn: async () => {
      const apires = await api.get<UserFrontData>('/app/userFrontData');
      return apires.data;
    },
    disabled: params?.disabled !== undefined && params?.disabled,
    fetchOnlyOnEmptyCache: !!params?.fetchOnlyOnEmptyCache
  });
};
/**
 * A hook to fetch all formAudits from one formanswer
 * @returns an useQuery hook to fetch all formAudits from one formanswer
 */
export const useFormAudits = (answerUlid: string) => {
  return useVoroQuery({
    queryKey: ['formAudits', answerUlid],
    queryFn: async () => {
      const apires = await api.get<FormAudit[]>('/app/audits', {
        params: {
          answerUlid: answerUlid
        }
      });
      return apires.data;
    },
    staleTime: 5 * 60 * 1000 // 5min
  });
};

/**
 * A hook to fetch all formInspections from one formanswer
 * @returns an useQuery hook to fetch all formInspections from one formanswer
 * @author Douglas Flores
 */
export const useFormAnswerInspections = (formAnswerUlid: string) => {
  const hook = useVoroQuery<FormInspection[]>({
    queryKey: ['formInspections', formAnswerUlid],
    queryFn: async () => {
      try {
        const apires = await api.get<FormInspection[]>(
          '/app/formInspections/fromFormAnswer',
          { params: { formAnswerUlid } }
        );
        return apires.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
  });
  return {
    ...hook,
    data:
      !!hook?.data && Array.isArray(hook.data)
        ? hook.data?.filter(insp => insp.ulid_formAnswer === formAnswerUlid)
        : hook.data
  };
};

/**
 * A hook to fetch all formInspections from one company
 * @returns an useQuery hook to fetch all formInspections from one company
 * @author Douglas Flores
 */
export const useFormInspections = () => {
  return useVoroQuery<any[]>({
    queryKey: ['formInspections'],
    queryFn: async () => {
      try {
        const apires = await api.get<FormInspection[]>(
          '/app/formInspections/all'
        );
        return apires.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    staleTime: 5 * 60 * 1000 // 5 min
  });
};

/**
 * A hook to fetch all formSignatures from a formAnswer
 * @param answerUlid the target formAnswer's ulid
 * @returns an useQuery hook to fetch all formSignatures from a formAnswer
 * @author Douglas Flores
 */
export const useFormSignatures = (
  answerUlid: string,
  params?: VoroQueryHookParams<FormSignatureDB[]>
) => {
  return useVoroQuery<FormSignatureDB[]>({
    ...params,
    queryKey: ['formSignatures', answerUlid],
    queryFn: async () => {
      const apires = await api.get<FormSignatureDB[]>(
        `/app/formSignature/getFromFA/${answerUlid}`
      );
      /* Updating cached form answers */
      const cached = await db.queryCache
        .filter(x => x.key.includes('cachedFormAnswers'))
        .toArray();
      const old = (cached[0].data as Array<AppLocalFormAnswer>) ?? [];
      await db.queryCache.update(['cachedFormAnswers'], {
        data: [
          ...old.map(formAnswer => {
            if (formAnswer?.answer?.metadata?.ulid === answerUlid)
              return {
                ...formAnswer,
                signatures: apires?.data?.map(s => formSignatureToSignature(s))
              };
            else return formAnswer;
          })
        ]
      });
      // Return the query's response
      return apires?.data;
    },
    staleTime: 15 * 1000 // 15s
  });
};

/**
 * A hook to fetch all formAnswerExemplaries from one company
 * @returns an useQuery hook to fetch all formAnswerExemplaries from one company
 * @author Douglas Flores
 */
export const useFormAnswerExemplaries = (
  params: VoroQueryHookParams<FormAnswerExemplary[]>
) => {
  const token = Cookies.get('token');
  const id_company =
    token && (JSON.parse(atob(token.split('.')[1]))?.id_company ?? 0);
  return useVoroQuery<FormAnswerExemplary[]>({
    ...params,
    disabled: id_company !== 1 || params?.disabled,
    queryKey: ['formAnswerExemplaries'],
    queryFn: () =>
      defaultQueryFn<FormAnswerExemplary[]>('/app/formAnswerExemplaries')
  });
};

/**
 * A hook to fetch all display data of the voroRoles of one company
 * @returns an useQuery hook to fetch all display data of the voroRoles of one company
 * @author Douglas Flores
 */
export const useVoroRoleDisplay = (
  params?: VoroQueryHookParams<VoroRoleDisplay[]>
) => {
  return useVoroQuery({
    queryKey: ['voroRoles'],
    queryFn: () => defaultQueryFn<VoroRoleRes>('/app/voroRoles'),
    disabled: params?.disabled,
    fetchOnlyOnEmptyCache: params?.fetchOnlyOnEmptyCache
  });
};
