/* Libs imports */
import React, { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
/* Icon imports */
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
/* Local imports */
import MainContainer from '../../components/MainContainer/MainContainer';
import NavBar from '../../components/NavBar';
import { useAppSelector } from '../../hooks/redux.hooks';
import theme from '../../theme';
import useStyles from '../../useStyles';
import FormAnswerStatusBanner from '../../components/FormAnswerStatusBanner';
import localStyles from './styles';
import FormAuditBanner from '../../components/FormAuditBanner';
import { useFormAnswer, useFormAudits } from '../../hooks/query.hooks';
import SnackbarAlert from '../../components/SnackbarAlert';
import useAlert from '../../hooks/useAlert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { AppLocalFormAnswer } from '../../../vorotypes/types/formAnswer';
import { FORM_TYPE_INTERNAL_CODE } from '../../../vorotypes/internalCodes/formType';
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.guess();

interface LocationState {
  availability: 'local' | 'remote';
}

export default function FormAnswerMenu(props: any) {
  const ulid = props.match.params.ulid;
  const token = Cookies.get('token');
  const {
    id_company,
    id_user,
    services: companyServices
  } = jwt_decode(token as string) as any;
  /* Getting selected answer */
  const { data: selectedAnswer, isReady } =
    useFormAnswer<AppLocalFormAnswer>(ulid);
  const { data: formAudits } = useFormAudits(ulid);
  /* Styles */
  const classes = useStyles(theme);
  const localClasses = localStyles();
  /* Hooks */
  const alertHook = useAlert();
  const isOnline = useAppSelector(state => state.session.online);
  const hasOfflineFirstIdService = useAppSelector(
    state => state.session.hasOfflineFirstIdService
  );
  const location = useLocation<LocationState>();
  const history = useHistory();
  /* States */
  const [id, setId] = useState<string>('');
  const [filledDate, setFilledDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [enableNonExecBtn, setEnableNonExecBtn] = useState(false);
  const [qrRISSDialogOpen, setQrRISSDialogOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  /* Functions */
  const handleReviewFA = () => {
    if (location?.state?.availability === 'remote')
      history.push(`/singleFA/${ulid}`);
    else history.push(`/singleFALocal/${ulid}`);
  };
  /* useEffects */
  useEffect(() => {
    /* Check if there is a form answer selected */
    if (!selectedAnswer) return;
    /* Setting answer id */
    if (
      (!!ulid && hasOfflineFirstIdService) ||
      (!selectedAnswer?.id && !!ulid)
    ) {
      setId(ulid);
    } else {
      const savedAnswerId =
        selectedAnswer?.answer?.metadata?.ulid ??
        selectedAnswer?.id?.toString();
      setId(savedAnswerId);
    }
    /* Setting start date */
    const finishedDate =
      selectedAnswer?.answer?.metadata?.timer?.answerFinishedDate;
    if (finishedDate)
      setFilledDate(dayjs(finishedDate).format('DD/MM/YYYY - HH:mm'));
    /* Setting end date */
    if (selectedAnswer?.answerStatus?.completionData)
      setEndDate(selectedAnswer?.answerStatus?.completionData?.datetime);
    else if (selectedAnswer?.answerStatus?.cancelData?.datetime)
      setEndDate(selectedAnswer?.answerStatus?.cancelData?.datetime);
    /* Defining nonExec button */
    const id_postedBy = selectedAnswer?.id_postedBy;
    const currentStatus = selectedAnswer?.answerStatus?.status;
    const isNonExec =
      Object.keys(selectedAnswer?.answer?.items ?? {}).includes(
        'NonExecution'
      ) || currentStatus === 'nonExecution';
    const wasCancelled = currentStatus === 'cancelled';
    const isAPR = FORM_TYPE_INTERNAL_CODE['apr'] === selectedAnswer.idFormType;
    const isAuthor = !id_postedBy || id_postedBy === id_user;
    setEnableNonExecBtn(isAPR && !isNonExec && !wasCancelled && isAuthor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswer, id_company, hasOfflineFirstIdService]);
  /* Return */

  return (
    <React.Fragment>
      <NavBar
        isLogged
        returnButton
        returnFunction={() => history.go(-1)}
        headerTitle={`${
          selectedAnswer?.answer?.metadata?.title ?? `DOC`
        } Digital`}
      />
      <MainContainer isOnline={isOnline} maxWidth={'xs'} bottomButtons={2}>
        <div className={classes.bodyList} style={{ gap: 12 }}>
          {id || !isOnline ? (
            id?.length > 10 ? (
              <Typography variant="h4" marginTop={0}>
                {`${selectedAnswer?.answer?.metadata?.title || 'DOC'}-${id}`}
              </Typography>
            ) : (
              <Typography variant="h1">{`${
                selectedAnswer?.answer?.metadata?.title || 'DOC'
              }-${id}`}</Typography>
            )
          ) : (
            <Skeleton width={150} />
          )}
          {isReady && !!selectedAnswer && (
            <FormAnswerStatusBanner
              formAnswerUlid={
                selectedAnswer.ulid ?? selectedAnswer?.answer?.metadata?.ulid
              }
              filledDate={filledDate}
              endDate={
                endDate
                  ? dayjs(endDate).format('DD/MM/YYYY - HH:mm')
                  : undefined
              }
              author={selectedAnswer?.name ?? ''}
              isPausable={false}
            />
          )}
          {!!formAudits?.length && (
            <FormAuditBanner ulid_formAnswer={ulid} formAudits={formAudits} />
          )}
          <div
            id="menu-options"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 5
            }}
          >
            <Button
              className={classes.arrowButton}
              onClick={handleReviewFA}
              style={{ gap: 12 }}
            >
              <DescriptionRoundedIcon color="primary" fontSize="medium" />
              <Grid container direction="column" alignItems="flex-start">
                <Grid item style={{ fontWeight: 700, fontSize: 16 }}>
                  Revisar preenchimento da{' '}
                  {selectedAnswer?.answer?.metadata?.title || 'DOC'}
                </Grid>
              </Grid>
              <ArrowForwardRoundedIcon color="primary" fontSize="medium" />
            </Button>
            <Divider />
            {companyServices.includes('riss') &&
              selectedAnswer?.answer?.metadata?.ulid &&
              FORM_TYPE_INTERNAL_CODE['apr'] === selectedAnswer?.idFormType && (
                <React.Fragment>
                  <Button
                    className={classes.arrowButton}
                    style={{ gap: 12 }}
                    onClick={() => setQrRISSDialogOpen(true)}
                  >
                    <QrCodeIcon fontSize="medium" />
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-start"
                      justifyContent={'flex-start'}
                    >
                      <Grid item style={{ fontWeight: 700, fontSize: 16 }}>
                        Submeter à RISS
                      </Grid>
                    </Grid>
                    <ArrowForwardRoundedIcon
                      color="primary"
                      fontSize="medium"
                    />
                  </Button>
                  <Divider />
                </React.Fragment>
              )}
            {enableNonExecBtn && (
              <Button
                className={classes.arrowButton}
                style={{ gap: 12 }}
                onClick={() => history.push(`/cancelRecentAnswer/${ulid}`)}
              >
                <ThumbDownAltOutlinedIcon fontSize="medium" />
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent={'flex-start'}
                >
                  <Grid item style={{ fontWeight: 700, fontSize: 16 }}>
                    Interromper Atividade
                  </Grid>
                </Grid>
                <ArrowForwardRoundedIcon color="primary" fontSize="medium" />
              </Button>
            )}
          </div>
          <div
            className={classes.footer}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <Button
              variant="contained"
              className={classes.nextButton}
              style={{
                fontWeight: 400,
                display: 'flex',
                gap: 8
              }}
              onClick={() => history.push('/home')}
            >
              <HomeRoundedIcon />
              Voltar à Tela de Início
            </Button>
          </div>
        </div>
      </MainContainer>
      <Dialog
        open={qrRISSDialogOpen}
        BackdropProps={{ className: classes.dialogBackdrop }}
        PaperProps={{ style: { borderRadius: 5 } }}
        onClose={() => setQrRISSDialogOpen(false)}
      >
        <DialogContent className={localClasses.dialogContent}>
          {selectedAnswer?.answer?.metadata?.ulid ? (
            <React.Fragment>
              <QRCodeSVG
                value={selectedAnswer?.answer?.metadata?.ulid}
                size={256}
                level={'H'}
                bgColor={'transparent'}
                fgColor={theme.palette.primary.main}
                includeMargin={true}
              />
              <div className={localClasses.linkShareContainer}>
                <Typography
                  overflow="hidden"
                  padding="0px 8px"
                  color={theme.palette.grey[700]}
                >
                  {selectedAnswer.answer.metadata.ulid}
                </Typography>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  title="Copiado!"
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => setTooltipOpen(false)}
                  open={tooltipOpen}
                >
                  <Button
                    variant="contained"
                    className={localClasses.copyButton}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        selectedAnswer.answer.metadata.ulid
                      );
                      setTooltipOpen(true);
                      setTimeout(() => setTooltipOpen(false), 2000);
                    }}
                  >
                    <ContentCopyRoundedIcon />
                  </Button>
                </Tooltip>
              </div>
            </React.Fragment>
          ) : (
            <div>Identificador não encontrado</div>
          )}
        </DialogContent>
      </Dialog>
      <SnackbarAlert
        {...alertHook.state}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ marginBottom: 50 }}
      />
    </React.Fragment>
  );
}
