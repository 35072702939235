/* Global imports */
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Skeleton, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { z } from 'zod';
/* Icon imports */
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
/* Local imports */
import authenticatedUseStyles from './styles';
import useStyles from '../../../../useStyles';
import {
  setFormanswer,
  setFormTemplate,
  setGroupAuthPhotos,
  updateParticipantSignature,
  updateParticipantSignatureList
} from '../../../../slices/formSlice';
import { resetNonConformingAttempts } from '../../../../slices/answerMetadataSlice';
import { getCurrentPosition } from '../../../../services/getPos';
import { version } from '../../../../version.json';
/* Component imports */
import PhotosInput from '../../../../components/PhotosInput';
import MainContainer from '../../../../components/MainContainer/MainContainer';
import NavBar from '../../../../components/NavBar';
import { RouterPrompt } from '../../../../components/RouterPrompt';
import SnackbarAlert from '../../../../components/SnackbarAlert';
import SuccessDialog from '../../../../components/SuccessDialog';
/* Hook imports */
import { useEditFormAnswer } from '../../../../hooks/mutation.hooks';
import useAlert from '../../../../hooks/useAlert';
import { useAppSelector, useAppDispatch } from '../../../../hooks/redux.hooks';
import {
  useCoworkers,
  useFormAnswer,
  useFormSignatures
} from '../../../../hooks/query.hooks';
/* Type imports */
import {
  FormAnswerEdit,
  ParticipantSignature,
  Photo
} from '../../../../types/form';
import { Coworker } from '../../../../../vorotypes/types/user';
import { AppLocalFormAnswer } from '../../../../../vorotypes/types/formAnswer';

dayjs.extend(utc);

/* --- Main Function --- */
export default function AuthCompleteForm(props: any) {
  const ulid = props.match.params.ulid;
  /* Styles */
  const theme = useTheme();
  const classes = useStyles(theme);
  const localClasses = authenticatedUseStyles(theme);
  /* Cookies */
  const token = Cookies.get('token');
  /* Hooks */
  const alertHook = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const completeFormAnswer = useEditFormAnswer(ulid);
  const { data: coworkers } = useCoworkers({ fetchOnlyOnEmptyCache: true });
  const { data: formSignatures } = useFormSignatures(ulid, {
    fetchOnlyOnEmptyCache: true
  });
  const { data: selectedAnswer, isReady } =
    useFormAnswer<AppLocalFormAnswer>(ulid);
  const formAnswerComplete = useAppSelector(
    state => state.form.formAnswerComplete
  );
  const isOnline = useAppSelector(state => state.session.online);
  const groupPhotos = useAppSelector(state => state.form.groupAuthPhotos);
  const participantSignatureList = useAppSelector(
    state => state.form.participantSignatureList
  );
  /* States */
  const [initializedSignatureList, setInitializedSignatureList] =
    useState<boolean>(false);
  const [thirdPartyPhotos, setThirdPartyPhotos] = useState<Photo[]>();
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  /* Const vars */
  const creatorId = token && (JSON.parse(atob(token.split('.')[1]))?.id ?? 0);
  const coworkersOptions: Coworker[] = coworkers?.coworkers || [];
  /* Functions */
  function hasThirdPartyUsers() {
    if (!selectedAnswer?.template) return false;
    const fields = selectedAnswer?.template.steps.flatMap(step => step.fields);
    const thirdPartyFields = fields.filter(
      field => field.type === 'ThirdPartyUsers'
    );
    const thirdPartyAnswers = thirdPartyFields.map(
      field => selectedAnswer.answer.items[field.field_id]
    );
    return (
      thirdPartyAnswers.length > 0 &&
      thirdPartyAnswers.some(ans => ans?.length > 0)
    );
  }
  function setGroupPhotos(event: any) {
    dispatch(setGroupAuthPhotos(event));
  }
  function successCheckout() {
    setSuccessDialogOpen(true);
    dispatch(resetNonConformingAttempts());
    setTimeout(() => history.push('/home'), 2 * 1000);
  }
  async function submit() {
    try {
      setIsSubmitting(true);
      /* Getting current position */
      const pos = await getCurrentPosition();

      /* Building data package */
      const payload = {
        signatures: participantSignatureList
          ?.filter(sig => !formSignatures?.some(fs => fs.id_user === sig.id))
          ?.map(sig =>
            Object({
              id: sig.id,
              date: sig?.date,
              signatureImage: sig?.signatureImage,
              photo: sig?.photo,
              personalId: sig?.personalId
            })
          ),
        authentication:
          !!groupPhotos || !!thirdPartyPhotos
            ? {
                general: {
                  photos: groupPhotos,
                  thirdPartySignatures: thirdPartyPhotos
                }
              }
            : undefined
      };
      if (formAnswerComplete) Object.assign(payload, formAnswerComplete);
      const edit: FormAnswerEdit = {
        route: 'app/formAnswer/complete',
        postData: {
          formAnswerUlid: ulid,
          action: 'complete',
          payload: payload,
          fieldId: 'status', // This must be set only for compatibility reasons with the formEdits that edits form's answer
          date: new Date().toISOString(),
          position: pos || undefined,
          appVersion: version
        }
      };

      /* Executing the mutation */
      completeFormAnswer.mutate(edit, {
        onSuccess: successCheckout,
        onError: err => {
          throw err;
        }
      });
    } catch (error: any) {
      console.error(error?.message);
      alertHook.error(
        'Erro ao completar atividade, tente novamente mais tarde.'
      );
    } finally {
      setIsSubmitting(false);
    }
  }
  function disabledButton() {
    const allSigned = participantSignatureList?.every(
      coworker => coworker.hasSigned
    );
    const thirdPartySatisfied = hasThirdPartyUsers()
      ? !!thirdPartyPhotos && thirdPartyPhotos.length
      : true;
    if (selectedAnswer?.template?.config?.requireGroupAuthPhoto)
      return (
        !allSigned ||
        !groupPhotos ||
        groupPhotos.length < 1 ||
        !thirdPartySatisfied
      );
    else return !allSigned || !thirdPartySatisfied;
  }
  function onParticipantClick(idx: number) {
    if (participantSignatureList)
      history.push(`/authReview/${participantSignatureList[idx].id}`);
  }
  function isParticipantDisabled(
    sig: ParticipantSignature,
    sigList: ParticipantSignature[]
  ) {
    const isLeader = sig.isLeader;
    const someDidNotSign = sigList
      .filter(signature => signature.id !== creatorId)
      .some(signature => !signature.hasSigned);
    const isRemote = formSignatures?.some(fs => fs.id_user === sig.id);
    return (isLeader && someDidNotSign) || isRemote;
  }
  /* Use Effects */
  useEffect(() => {
    if (!!selectedAnswer) {
      dispatch(setFormTemplate({ template: selectedAnswer.template } as any));
      dispatch(setFormanswer(selectedAnswer.answer.items));
      dispatch(updateParticipantSignatureList(creatorId));
      setInitializedSignatureList(true);
    }
  }, [selectedAnswer]);
  useEffect(() => {
    if (Array.isArray(formSignatures)) {
      // Inserting placeholder signatures for the remote signatures
      formSignatures.forEach(fs => {
        const signaturePayload: ParticipantSignature = {
          id: fs.id_user,
          hasSigned: true,
          isLeader: false
        };
        // Only sets the signature if the formSignature is from one participant
        if (participantSignatureList?.some(ps => ps.id === fs.id_user))
          dispatch(updateParticipantSignature(signaturePayload));
      });
    }
  }, [formSignatures, initializedSignatureList]);
  /* --- Return --- */
  return (
    <Fragment>
      <NavBar
        isLogged
        headerTitle="Concluir Atividade"
        returnButton
        returnFunction={history.goBack}
      />
      <MainContainer isOnline={isOnline} maxWidth={'xs'} bottomButtons={1}>
        <Typography
          variant="h2"
          mt={1}
          display="flex"
          alignItems="center"
          gap={1}
          fontWeight={900}
        >
          {!!selectedAnswer ? (
            <Fragment>
              <AssignmentTurnedInRoundedIcon style={{ fontSize: 22 }} />
              Concluir
            </Fragment>
          ) : (
            <Skeleton width={260} />
          )}
        </Typography>
        <Typography variant="h4" mt={2} marginBottom={2}>
          {!!selectedAnswer && isReady ? (
            `${selectedAnswer?.answer?.metadata?.title ?? 'DOC'}-${ulid}`
          ) : (
            <Skeleton width={260} />
          )}
        </Typography>
        <Typography marginBlock={2}>
          Revise as informações preenchidas e colete as assinaturas dos
          participantes:
        </Typography>

        <div className={localClasses.buttonList}>
          {!!participantSignatureList ? (
            participantSignatureList?.map((coworker, index) => (
              <Button
                id={`btn-coworker-${index}`}
                key={coworker.id}
                onClick={() => onParticipantClick(index)}
                style={{
                  backgroundColor: coworker.hasSigned ? '#256224' : '#0b0b471A',
                  color: coworker.hasSigned
                    ? '#FFFFFF'
                    : theme.palette.primary.main
                }}
                className={localClasses.basePerformerButton}
                disabled={isParticipantDisabled(
                  coworker,
                  participantSignatureList
                )}
              >
                <div className={localClasses.sigBtnMainDiv}>
                  <Typography fontSize={16} fontWeight={600}>
                    {coworkersOptions.find(it => it.id === coworker.id)?.name}
                  </Typography>

                  <Typography
                    variant="body2"
                    fontWeight={500}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    {coworker?.hasSigned ? (
                      'Assinado'
                    ) : coworker.isLeader &&
                      participantSignatureList
                        .filter(coworker => coworker.id !== creatorId)
                        .some(coworker => !coworker.hasSigned) ? (
                      'Criador do documento assina por último'
                    ) : (
                      <Fragment>
                        <ErrorIcon
                          fontSize="small"
                          style={{ marginBottom: 2 }}
                        />
                        Não assinado
                      </Fragment>
                    )}
                  </Typography>
                </div>

                <div style={{ alignSelf: 'center', height: 26 }}>
                  {coworker.hasSigned ? (
                    <CheckCircleIcon style={{ fontSize: 26 }} />
                  ) : coworker.isLeader &&
                    participantSignatureList
                      .filter(coworker => coworker.id !== creatorId)
                      .some(coworker => !coworker.hasSigned) ? (
                    <ErrorIcon style={{ fontSize: 26 }} />
                  ) : (
                    <ArrowForwardIcon style={{ fontSize: 26 }} />
                  )}
                </div>
              </Button>
            ))
          ) : (
            <Skeleton variant="rectangular" width="100%" height={80} />
          )}
        </div>
        {(selectedAnswer?.template?.config?.requireGroupAuthPhoto ||
          selectedAnswer?.template?.config?.optionalGroupAuthPhoto) && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Typography variant="h3" marginTop={6}>
              Fotos dos Envolvidos
            </Typography>
            <PhotosInput
              photos={groupPhotos ?? []}
              onChange={setGroupPhotos}
              height={100}
            />
          </div>
        )}
        {hasThirdPartyUsers() && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <Typography variant="h3" marginTop={6}>
              Assinaturas dos Participantes
            </Typography>
            <PhotosInput
              photos={thirdPartyPhotos ?? []}
              onChange={val => setThirdPartyPhotos(val)}
              height={100}
            />
          </div>
        )}
      </MainContainer>

      <div className={classes.footer} style={{ gap: 8 }}>
        <LoadingButton
          id="btn-send-form"
          className={classes.nextButton}
          disabled={disabledButton()}
          variant="contained"
          onClick={submit}
          loading={completeFormAnswer.isMutating || isSubmitting}
        >
          Enviar
        </LoadingButton>
      </div>
      <SuccessDialog open={successDialogOpen} />
      <RouterPrompt
        when={pathname =>
          !pathname.startsWith('/authReview/') &&
          !pathname.startsWith('/authComplete/') &&
          !successDialogOpen
        }
        title="Você realmente deseja sair desta página?"
        body={'Se você sair desta página agora, as assinaturas serão perdidas.'}
      />
      <SnackbarAlert {...alertHook.state} />
    </Fragment>
  );
}
