import { useEffect, useState } from 'react';
import db from '../services/db';
import {
  UseQueryResult,
  UseQueryResultState,
  VoroQueryOptions,
  VoroQueryStatus
} from '../types/voroquery';
import voroQueryClient, { MutationEvent } from '../services/voroQueryClient';
import { subscribe, unsubscribe } from '../services/events';

/**
 * Hook to fetch data online and offline
 * @param params refer to interface VoroQueryOptions
 * @returns a hook to fetch data in the api or in the IndexedDB
 */
export function useVoroQuery<TQueryFnData = unknown>(
  params: VoroQueryOptions<TQueryFnData>
): UseQueryResult<TQueryFnData> {
  // The response object
  const [response, setResponse] = useState<UseQueryResultState<TQueryFnData>>({
    data: undefined,
    status: 'idle'
  });

  /* 🟩 When online:
   * 1. Must change status to 'fetching'
   * 2. Must query the api
   * 3. Must update local DB with updated data
   * 4. Must return the response from the api
   */
  async function fetchOnline(status: VoroQueryStatus): Promise<{
    data?: TQueryFnData;
    error?: unknown;
  }> {
    const permissionArray = [
      !!navigator.onLine,
      !params?.disabled,
      !params?.fetchOnlyOnEmptyCache ||
        (!!params?.fetchOnlyOnEmptyCache && status === 'empty')
    ];
    if (permissionArray.every(condition => condition === true)) {
      setResponse(prev => Object({ ...prev, status: 'fetching' }));
      try {
        const queryResult = params.queryFn();
        if (queryResult instanceof Promise) {
          const apires = (await queryResult) as TQueryFnData;
          if (params.queryKey.some(key => !key)) {
            setResponse({ data: apires, status: 'error' });
            console.warn(
              `Undefined queryKey! Couldn't cache data! Query key provided: [${params?.queryKey}]`
            );
          } else {
            db.queryCache.put({
              key: params.queryKey,
              data: apires
            });
            setResponse({ data: apires, status: 'success' });
          }
          return { data: apires };
        } else {
          db.queryCache.put({
            key: params.queryKey,
            data: queryResult
          });
          setResponse({ data: queryResult, status: 'success' });
          return { data: queryResult };
        }
      } catch (error) {
        console.error(error);
        setResponse(prev => Object({ ...prev, status: 'error' }));
        return { error };
      }
    }
    return { data: undefined, error: new Error('Cannot fetch data now!') };
  }

  /* 🟥 When offline:
   * 1. Must set status to 'loading'
   * 2. Must fetch the data in the IndexedDB if it's present
   */
  function fetchLocally() {
    setResponse(prev => Object({ ...prev, status: 'loading' }));
    db.queryCache
      .filter(x => params.queryKey.every(y => x.key?.includes(y)))
      .toArray()
      .then(dbres => {
        if (dbres[0]) {
          setResponse({
            data: dbres[0].data as TQueryFnData,
            status: 'success'
          });
        } else {
          setResponse(prev =>
            !['success', 'error'].includes(prev.status)
              ? { ...response, status: 'empty' }
              : prev
          );
        }
      })
      .catch(err => {
        console.error(err);
        if (!!params?.onError) params.onError(err);
        setResponse(prev => Object({ ...prev, status: 'error' }));
      });
  }
  /**
   * @description used as a cleanup for the response state paired withe an useEffect
   * @returns a cleanup function for the voroQuery response state
   * @author Douglas Flores
   */
  const cleanup = () => {
    return () => setResponse({ data: undefined, status: 'idle' });
  };
  /**
   * Sets the timer to make the query stale
   * @returns a cleanup of the interval
   * @author Douglas Flores
   */
  const staleTimerSet = () => {
    let staleTimer: NodeJS.Timeout | null = null;
    if (!!params?.staleTime && params.staleTime > 0)
      staleTimer = setInterval(() => {
        setResponse(prev => Object({ ...prev, status: 'stale' }));
      }, params.staleTime);

    return () => (!!staleTimer ? clearInterval(staleTimer) : undefined);
  };
  const idle = () => setResponse(prev => ({ ...prev, status: 'idle' }));
  async function refetch(): Promise<{ data?: TQueryFnData; error?: unknown }> {
    if (response.status === 'idle') fetchLocally();
    try {
      const pendingMutations = await voroQueryClient.getPendingMutations();
      if (pendingMutations?.length > 0) {
        const warnMsg = `Cannot fetch ${params.queryKey} because there is pending mutations.`;
        console.warn(warnMsg);
        return { error: new Error(warnMsg) };
      }
    } catch (error) {
      console.error(error);
      return { error: new Error('Failed to check pending mutations') };
    }
    return await fetchOnline(response.status);
  }
  /**
   * Subscribes to the events that voroQuery must listen
   * @returns a cleanup function to unsubscribe
   * @author Douglas Flores
   */
  const subscriptions = () => {
    const listener = () => {
      setResponse(prev => Object({ ...prev, status: 'stale' }));
    };
    subscribe<MutationEvent>('mutationssynched', listener);
    return () => unsubscribe<MutationEvent>('mutationssynched', listener);
  };
  // eslint-disable-next-line
  useEffect(staleTimerSet, []);
  useEffect(subscriptions, []);
  useEffect(cleanup, []);

  /* Executing the functions */
  useEffect(() => {
    if (['idle', 'stale', 'empty'].includes(response.status)) {
      if (response.status === 'idle') fetchLocally();
      voroQueryClient
        .getPendingMutations()
        .then(pendingMutations => {
          if (pendingMutations?.length > 0) {
            console.warn(
              `Cannot fetch ${params.queryKey} because there is pending mutations.`
            );
          } else {
            fetchOnline(response.status);
          }
        })
        .catch(err => {
          console.error(err);
          fetchOnline(response.status);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.status]);

  /* Always returns the response object with extra metadata */
  return {
    ...response,
    isReady: response.status === 'success',
    isQuerying: ['loading', 'fetching'].includes(response.status),
    isLoading: response.status === 'loading',
    isFetching: response.status === 'fetching',
    isError: response.status === 'error',
    isEmpty: response.status === 'empty',
    isIdle: response.status === 'idle',
    isStale: response.status === 'stale',
    refetch
  };
}
