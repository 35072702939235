import React, { Fragment, useMemo, lazy } from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import dayjs from 'dayjs';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import formAnswerStyles from '../../styles';
import AnswerGeneralPhotos from '../AnswerGeneralPhotos';
import theme from '../../../../theme';
import CheckBoxRow from '../../../CheckBoxRow';
import { extractOtherOptionText, getEditDate } from '../../../../utils/form';
import { BusinessUnit } from '../../../../../vorotypes/types/businessUnit';
import { useBusinessUnits, useDqsTopics } from '../../../../hooks/query.hooks';
import { UseQueryResult } from '../../../../types/voroquery';
import {
  FormField,
  FormStep,
  FormStepType
} from '../../../../../vorotypes/types/formTemplate';
import { Coworker } from '../../../../../vorotypes/types/user';
import {
  GeneralPhoto,
  GroupRissAnswer,
  IndividualRissAnswer
} from '../../../../types/form';
import { DqsTopicExtended } from '../../../../../vorotypes/types/dqsTopic';
import { SHORT_REFERENCE_PERIOD_DICTIONARY } from '../../../../../vorotypes/internalCodes/dqsTopic';
import { conditionsSatisfied } from '../../../../pages/FilloutForm/components/FieldList/helpers';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { secondsToFullTime } from '../../../../utils/misc';

export interface RenderStepsProps {
  steps: Array<FormStep>;
  answer: { [key: string]: any };
  coworkers: Array<Coworker>;
  loadingCoworkers: boolean;
  edits?: any[];
}

export default function RenderSteps(props: RenderStepsProps) {
  const { steps, answer, coworkers, loadingCoworkers, edits } = props;
  const businessUnitsQuery = useBusinessUnits();
  const dqsTopicsQuery = useDqsTopics();

  return (
    <React.Fragment>
      {steps.map(step =>
        step.fields.map(field => (
          <RenderField
            key={field.field_id}
            field={field}
            answer={answer}
            coworkers={coworkers}
            loadingCoworkers={loadingCoworkers}
            businessUnitsQuery={businessUnitsQuery}
            dqsTopicsQuery={dqsTopicsQuery}
            edits={edits}
            stepType={step?.type}
          />
        ))
      )}
    </React.Fragment>
  );
}

interface RenderFieldProps {
  field: FormField;
  stepType: FormStepType;
  answer: { [key: string]: any };
  coworkers: Array<Coworker>;
  loadingCoworkers: boolean;
  businessUnitsQuery: UseQueryResult<BusinessUnit[]>;
  dqsTopicsQuery: UseQueryResult<DqsTopicExtended[]>;
  edits?: any[];
}

export function RenderField(props: RenderFieldProps) {
  const {
    field,
    stepType,
    answer,
    coworkers,
    loadingCoworkers,
    businessUnitsQuery,
    dqsTopicsQuery,
    edits
  } = props;
  const answerClasses = formAnswerStyles();
  try {
    const field_answer = answer[field.field_id];
    if (!field_answer) throw new Error('No answer found');
    if (Array.isArray(field_answer) && field_answer.length === 0)
      return <React.Fragment key={`${field.field_id}`} />;
    return (
      <div key={field.field_id} className={answerClasses.rowContainer}>
        {/* --- Title --- */}
        <Typography className={answerClasses.cellTitle} marginTop={4}>
          {field.field_id === 'paperForm'
            ? 'APR em papel (sem visualização local disponível)'
            : !!field?.properties?.question?.length
            ? field?.properties?.question
            : field?.properties?.description}
        </Typography>
        <RenderFieldAnswer
          field={field}
          fieldAnswer={field_answer}
          answer={answer}
          coworkers={coworkers}
          loadingCoworkers={loadingCoworkers}
          businessUnitsQuery={businessUnitsQuery}
          dqsTopicsQuery={dqsTopicsQuery}
          edits={edits}
          stepType={stepType}
        />
      </div>
    );
  } catch (error) {
    return <React.Fragment key={field.field_id} />;
  }
}

interface RenderFieldAnswerProps extends RenderFieldProps {
  fieldAnswer: any;
  stepType: FormStepType;
}

function RenderFieldAnswer(props: RenderFieldAnswerProps) {
  const {
    field,
    fieldAnswer,
    answer,
    coworkers,
    loadingCoworkers,
    businessUnitsQuery,
    dqsTopicsQuery,
    stepType,
    edits
  } = props;

  if (
    ['performers', 'Users'].includes(field?.field_id) ||
    field.type === 'Users'
  ) {
    /* --- List of participants --- */
    return (
      <RenderParticipants
        fieldAnswer={fieldAnswer}
        loadingParticipants={loadingCoworkers}
        participants={coworkers}
      />
    );
  } else if (field?.type === 'User') {
    /* --- Single user --- */
    return (
      <RenderParticipant
        fieldAnswer={fieldAnswer}
        loadingParticipants={loadingCoworkers}
        participants={coworkers}
      />
    );
  } else if (isPhotoQuestion(field)) {
    /* --- Photo Question --- */
    return <RenderPhotos fieldAnswer={fieldAnswer} />;
  } else if (isYesNoNaQuestion(field, stepType)) {
    /* Yes or No questions */
    return (
      <RenderYesNo field={field} fieldAnswer={fieldAnswer} edits={edits} />
    );
  } else if (field.type === 'Date') {
    /* --- Date type --- */
    return (
      <Typography>{dayjs(fieldAnswer || '').format('DD/MM/YYYY')}</Typography>
    );
  } else if (field.type === 'MiscMeasurements') {
    /* --- Misc Measurements --- */
    return <RenderMiscMeasurements answer={answer} fieldAnswer={fieldAnswer} />;
  } else if (field.type === 'Measurement') {
    return (
      <RenderMeasurements
        field={field}
        fieldAnswer={fieldAnswer}
        edits={edits}
      />
    );
  } else if (field.type === 'RissRatingQuestion') {
    /* --- RISS Rating Question --- */
    return (
      <RenderRissRatingQuestion
        answer={answer}
        coworkers={coworkers}
        loadingCoworkers={loadingCoworkers}
        fieldAnswer={fieldAnswer}
      />
    );
  } else if (['BusinessUnit', 'BusinessUnits'].includes(field?.type ?? '')) {
    return (
      <RenderBusinessUnits
        fieldAnswer={fieldAnswer}
        businessUnitsQuery={businessUnitsQuery}
      />
    );
  } else if (field?.type === 'DqsTopic') {
    return (
      <RenderDqsTopic
        fieldAnswer={fieldAnswer}
        dqsTopicsQuery={dqsTopicsQuery}
      />
    );
  } else if (['TextList', 'ThirdPartyUsers'].includes(field?.type ?? '')) {
    return <RenderTextList fieldAnswer={fieldAnswer} />;
  } else if (Array.isArray(fieldAnswer)) {
    /* --- Arrays --- */
    return (
      <RenderArray
        answer={answer}
        field={field}
        fieldAnswer={fieldAnswer}
        edits={edits}
      />
    );
  } else if (field?.type === 'Fleet') {
    /* --- Fleet --- */
    return (
      <Typography>
        {!!fieldAnswer && !!fieldAnswer?.model ? (
          `${fieldAnswer['model']} - ${fieldAnswer['license_plate']}`
        ) : (
          <i style={{ color: theme.palette.grey[600] }}>Não informada</i>
        )}
      </Typography>
    );
  } else if (field.field_id === 'fleet') {
    /* --- Fleet from RegisterFA --- */
    return (
      <Typography>
        {!!fieldAnswer && !!fieldAnswer?.description ? (
          `${fieldAnswer['description']} - ${fieldAnswer['license_plate']}`
        ) : (
          <i style={{ color: theme.palette.grey[600] }}>Não informada</i>
        )}
      </Typography>
    );
  } else if (field.type === 'EstimatedDuration') {
    if (typeof fieldAnswer === 'number') {
      return <Typography>{secondsToFullTime(fieldAnswer)}</Typography>;
    } else {
      return <Typography>{fieldAnswer}</Typography>;
    }
  } else if (!fieldAnswer) {
    /* --- Erro 404 --- */
    return (
      <Typography color={theme.palette.grey[600]} fontStyle="italic">
        Resposta não encontrada
      </Typography>
    );
  } else {
    /* --- Default Rendering --- */
    return <div>{fieldAnswer}</div>;
  }
}

interface RenderParticipantsProps {
  loadingParticipants: boolean;
  participants: Array<Coworker>;
  fieldAnswer: Array<number | Coworker>;
}

function RenderParticipants(props: RenderParticipantsProps) {
  const { loadingParticipants, participants, fieldAnswer } = props;
  const answerClasses = formAnswerStyles();
  return !loadingParticipants && !!participants ? (
    <ul className={answerClasses.listAnswer}>
      {fieldAnswer.map((coworker: number | Coworker) => {
        if (typeof coworker === 'number')
          return (
            <li key={coworker}>
              {participants.find(it => it.id === coworker)?.name}
            </li>
          );
        else return <li key={coworker.id}>{coworker?.name}</li>;
      })}
    </ul>
  ) : (
    <Skeleton width={120} />
  );
}

function RenderParticipant(props: RenderParticipantsProps) {
  const { loadingParticipants, participants, fieldAnswer } = props;
  return (
    <React.Fragment>
      {!loadingParticipants && !!participants ? (
        <Typography>
          {
            participants.find(
              participants => participants.id === fieldAnswer[0]
            )?.name
          }
        </Typography>
      ) : (
        <Skeleton width={120} />
      )}
    </React.Fragment>
  );
}

interface RenderPhotosProps {
  fieldAnswer: Array<GeneralPhoto>;
}

function RenderPhotos(props: RenderPhotosProps) {
  const { fieldAnswer } = props;
  const answerClasses = formAnswerStyles();
  return (
    <div
      className={clsx(
        answerClasses.horizontalScroll,
        answerClasses.photosContainer
      )}
    >
      {!!fieldAnswer?.length ? (
        <AnswerGeneralPhotos photos={fieldAnswer} />
      ) : (
        <Typography fontStyle="italic">Nenhuma foto encontrada.</Typography>
      )}
    </div>
  );
}

interface RenderMiscMeasurementsProps {
  fieldAnswer: Array<any>;
  answer: any;
}

function RenderMiscMeasurements(props: RenderMiscMeasurementsProps) {
  const { fieldAnswer, answer } = props;
  const answerClasses = formAnswerStyles();
  return (
    <ul className={answerClasses.listAnswer}>
      {fieldAnswer?.map((el: any, idx: number) => {
        const measure = answer[el];
        return (
          <li key={`${measure?.uuid}-${idx}`}>
            <Typography>
              <b>{measure?.title}:</b> {measure?.val}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
}

interface RenderMeasurementsProps {
  field: FormField;
  fieldAnswer: Array<any>;
  edits?: Array<any>;
}

function RenderMeasurements(props: RenderMeasurementsProps) {
  const { field, fieldAnswer, edits } = props;
  const answerClasses = formAnswerStyles();
  if (fieldAnswer.length <= 0) {
    return (
      <Typography variant="body2" fontStyle="italic">
        Nenhuma medição.
      </Typography>
    );
  } else {
    return (
      <ul className={answerClasses.listAnswer}>
        {fieldAnswer.map((el: any, index: number) => {
          let plt: string | undefined = el;
          if (!!edits) {
            const date = getEditDate(edits, field.field_id, fieldAnswer, index);
            return !!date && typeof date !== 'number' ? (
              <li key={index} style={{ marginBottom: 5 }}>
                <Typography>
                  <b>{date}:</b> {plt}
                  {field?.measurement_unit}
                </Typography>
              </li>
            ) : (
              <li key={index} style={{ marginBottom: 5 }}>
                <b>Inicial:</b> {plt}
                {field?.measurement_unit}
              </li>
            );
          } else
            return (
              <li key={index} style={{ marginBottom: 5 }}>
                Inicial: {plt}
                {field?.measurement_unit}
              </li>
            );
        })}
      </ul>
    );
  }
}

interface RenderTextListProps {
  fieldAnswer: Array<any>;
}

function RenderTextList(props: RenderTextListProps) {
  const { fieldAnswer } = props;
  const answerClasses = formAnswerStyles();
  return (
    <ul className={answerClasses.listAnswer}>
      {fieldAnswer.map((text: string, idx: number) => {
        return <li key={idx}>{text}</li>;
      })}
    </ul>
  );
}

interface RenderArrayProps {
  field: FormField;
  fieldAnswer: Array<any>;
  answer: any;
  edits?: Array<any>;
}

function RenderArray(props: RenderArrayProps) {
  const { field, fieldAnswer, answer, edits } = props;
  const answerClasses = formAnswerStyles();
  const formComplements = useAppSelector(state => state.form.formComplements);
  return (
    <div className={answerClasses.rowContainer}>
      <div className={answerClasses.checkboxList}>
        {field?.properties?.options
          ?.filter(op => {
            if (!op?.conditions) return true;
            if (op.option_id === '$other$') return false;
            if (!formComplements) return true;
            return conditionsSatisfied(op.conditions, answer, formComplements);
          })
          ?.map((el: any, index: any) => {
            let localEdits = edits?.filter(
              edit => edit.idField === field.field_id
            );
            let selectedEdit = [];
            if (!!localEdits?.length) {
              selectedEdit = localEdits.filter(
                (edit: any) =>
                  edit.payload.filter(
                    (payload: string) => payload === el?.option_id
                  ).length > 0
              );
            }
            if (el?.text) {
              return (
                <CheckBoxRow
                  key={index}
                  isChecked={
                    !!fieldAnswer.find((ans: any) => ans === el?.option_id)
                  }
                >
                  <Typography>
                    {el?.text}
                    {selectedEdit?.length > 0 && (
                      <i
                        style={{
                          fontSize: 12,
                          color: theme.palette.primary.light
                        }}
                      >
                        {` (${
                          selectedEdit[selectedEdit.length - 1]?.action ===
                          'add'
                            ? 'Adicionado'
                            : 'Modificado'
                        } em 
                    ${dayjs(selectedEdit[selectedEdit.length - 1].date).format(
                      'DD/MM/YYYY, HH:mm'
                    )})`}
                      </i>
                    )}
                  </Typography>
                </CheckBoxRow>
              );
            } else {
              return <Typography key={index}>{fieldAnswer}</Typography>;
            }
          })}
        {/* Showing other options */}
        {field?.type === 'CheckBox' && field?.hasOtherOption ? (
          <React.Fragment>
            {fieldAnswer
              ?.find((ans: any) => ans?.others)
              ?.others?.map((op: any) => (
                <CheckBoxRow key={op.option_id} isChecked>
                  <Typography>
                    <b>Outro:</b> {op?.text}
                  </Typography>
                </CheckBoxRow>
              )) ?? <></>}
          </React.Fragment>
        ) : (
          fieldAnswer
            ?.filter(
              (ans: any) =>
                !field?.properties?.options?.find(
                  (op: any) => op?.option_id === ans
                )
            )
            ?.map((option: any, index: number) => {
              let text = extractOtherOptionText(option);
              if (!!text && text.length > 0) {
                return (
                  <CheckBoxRow key={`other-${index}`} isChecked>
                    <Typography>
                      <b>Outros:</b> {text}
                    </Typography>
                  </CheckBoxRow>
                );
              } else {
                return <React.Fragment key={`other-${index}`} />;
              }
            })
        )}
      </div>
    </div>
  );
}

interface RenderYesNoProps {
  field: FormField;
  fieldAnswer: 's' | 'n' | 'na';
  edits?: Array<any>;
}

function RenderYesNo(props: RenderYesNoProps) {
  const { field, fieldAnswer, edits } = props;
  return (
    <React.Fragment>
      <Typography>
        {fieldAnswer === 's'
          ? 'Sim.'
          : fieldAnswer === 'n'
          ? 'Não.'
          : fieldAnswer === 'na'
          ? 'Não se aplica.'
          : 'Resposta inválida.'}
      </Typography>
      {!!edits &&
        !!edits?.filter(
          edit =>
            edit?.idField === field.field_id || edit?.fieldId === field.field_id
        )?.length && (
          <Typography fontSize={10} color={theme.palette.grey[600]}>
            {`Editado em ${getEditDate(
              edits,
              field.field_id,
              fieldAnswer,
              edits?.filter(
                edit =>
                  edit?.idField === field.field_id ||
                  edit?.fieldId === field.field_id
              )?.length - 1
            )}`}
          </Typography>
        )}
    </React.Fragment>
  );
}

interface RenderRissRatingQuestionProps {
  answer: {
    // This is only required to make a special display case for a riss with only one inspected user
    [key: string]: any;
  };
  fieldAnswer: Array<IndividualRissAnswer> | GroupRissAnswer;
  coworkers: Array<Coworker>;
  loadingCoworkers: boolean;
  isSingleInspected?: boolean;
}

function RenderRissRatingQuestion(props: RenderRissRatingQuestionProps) {
  const { answer, fieldAnswer, coworkers, loadingCoworkers } = props;
  const answerClasses = formAnswerStyles();
  const inspected = answer['inspected'];

  if (Array.isArray(fieldAnswer)) {
    return !loadingCoworkers && !!coworkers ? (
      <div className={answerClasses.usersRissAnswerDiv}>
        {fieldAnswer.map((ans, idx: number) => {
          const coworker = coworkers.find(c => c.id === ans.id_user) ?? {
            id: ans,
            name: `Usuário de ID${ans}`
          };

          return (
            <div key={`riss-${idx}`} className={answerClasses.rissUserTip}>
              <div className={answerClasses.userTip}>
                <AccountCircleRoundedIcon
                  fontSize="small"
                  style={{
                    color: theme.palette.primary.light
                  }}
                />
                <Typography fontWeight={500} fontSize={16}>
                  {coworker.name}
                </Typography>
              </div>
              <div style={{ display: 'flex', gap: 16 }}>
                <CheckBoxRow isChecked={ans?.rating === 'ok'}>
                  <Typography textTransform="uppercase">ok</Typography>
                </CheckBoxRow>
                <CheckBoxRow isChecked={ans?.rating === 'na'}>
                  <Typography textTransform="uppercase">na</Typography>
                </CheckBoxRow>
                <CheckBoxRow isChecked={ans?.rating === 'ci'}>
                  <Typography textTransform="uppercase">ci</Typography>
                </CheckBoxRow>
                <CheckBoxRow isChecked={ans?.rating === 'ai'}>
                  <Typography textTransform="uppercase">ai</Typography>
                </CheckBoxRow>
              </div>
              {ans?.observation && (
                <Typography>
                  <b>Observação:</b> {ans.observation}
                </Typography>
              )}
              {ans?.photos && (
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    width: '100%',
                    flexWrap: 'wrap'
                  }}
                >
                  <AnswerGeneralPhotos photos={ans.photos} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    ) : (
      <Skeleton width={120} />
    );
  } else {
    return (
      <div className={answerClasses.miniFlex} style={{ marginTop: 4 }}>
        {inspected?.length > 1 && (
          <Typography fontStyle="italic">
            *Resposta válida para todo o grupo
          </Typography>
        )}
        <div className={answerClasses.miniFlex}>
          <CheckBoxRow isChecked={fieldAnswer?.rating === 'ok'}>
            <Typography textTransform="uppercase">ok</Typography>
          </CheckBoxRow>
          <CheckBoxRow isChecked={fieldAnswer?.rating === 'na'}>
            <Typography textTransform="uppercase">na</Typography>
          </CheckBoxRow>
          <CheckBoxRow isChecked={fieldAnswer?.rating === 'ci'}>
            <Typography textTransform="uppercase">ci</Typography>
          </CheckBoxRow>
          <CheckBoxRow isChecked={fieldAnswer?.rating === 'ai'}>
            <Typography textTransform="uppercase">ai</Typography>
          </CheckBoxRow>
        </div>
        {fieldAnswer?.observation && (
          <Typography>
            <b>Observação:</b> {fieldAnswer?.observation}
          </Typography>
        )}
        {fieldAnswer?.photos && (
          <div
            style={{
              display: 'flex',
              gap: 8,
              width: '100%',
              flexWrap: 'wrap'
            }}
          >
            <AnswerGeneralPhotos photos={fieldAnswer.photos} />
          </div>
        )}
      </div>
    );
  }
}

interface RenderBusinessUnitsProps {
  fieldAnswer: Array<any>;
  businessUnitsQuery: UseQueryResult<BusinessUnit[]>;
}

function RenderBusinessUnits(props: RenderBusinessUnitsProps) {
  const { fieldAnswer, businessUnitsQuery } = props;
  const answerClasses = formAnswerStyles();
  const { data: businessUnits } = businessUnitsQuery;
  return (
    <div className={answerClasses.rowContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4
        }}
      >
        {fieldAnswer.map((id: number) => {
          const bu = businessUnits?.find(b => b.id === id);
          if (!bu) return <div key={`bu-${id}`}>{`ID: ${id}`}</div>;
          else
            return (
              <Typography key={`bu-${id}`}>
                {bu.name} ({bu.city}
                {bu?.state && ` - ${bu.state}`})
              </Typography>
            );
        })}
      </div>
    </div>
  );
}

function isPhotoQuestion(field: FormField) {
  const isType = ['PhotoQuestion', 'DetachedPhotoQuestion', 'Photo'].includes(
    field?.type ?? ''
  );
  const isId = ['photos', 'photos_place', 'photos_performers'].includes(
    field.field_id
  );
  return isType || isId;
}

function isYesNoNaQuestion(field: FormField, stepType: FormStepType) {
  return [
    'TernaryQuestion',
    'TernaryGroup',
    'BinaryQuestion',
    'Execution'
  ].includes(field?.type ?? stepType);
}

interface RenderDqsTopicProps {
  fieldAnswer: any;
  dqsTopicsQuery: UseQueryResult<DqsTopicExtended[]>;
}

function RenderDqsTopic(props: RenderDqsTopicProps) {
  const { fieldAnswer, dqsTopicsQuery } = props;
  const answerClasses = formAnswerStyles();
  const { data: dqsTopics, isLoading } = dqsTopicsQuery;
  const topic = useMemo(
    () => dqsTopics?.find(dqsTopic => dqsTopic.id === fieldAnswer),
    [dqsTopics]
  );
  return (
    <div className={answerClasses.rowContainer}>
      <Typography>
        {isLoading ? (
          <Skeleton width={128} />
        ) : !!topic ? (
          `${topic.title}${
            !!topic?.referencePeriod
              ? ` - ${
                  SHORT_REFERENCE_PERIOD_DICTIONARY[topic?.referencePeriod]
                }`
              : ''
          } (${topic.referenceMonthYear})`
        ) : (
          <Fragment>Tema não encontrado</Fragment>
        )}
      </Typography>
    </div>
  );
}
